<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from 'v-money';

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  data() {
    return {
      type: {
        id: null,
        status: null,
        name: '...',
        description: null,
        time: null,
        value_min: null,
        value_total: null,
        value_captured: null,
        value_captured_percent: null,
        value_captured_contracts: null,
        cover: null,
      },

      contract: {
        loading: false,
        value: '0,00',
      },

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },

      alert: {
        contract: { type: '', message: '' },
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    contract: {
      value: { required },
    }
  },
  methods: {
    getType() {
      api.get("investments/types/" + this.$route.params.id).then((response) => {
        if (response.data.status == "success") {
          this.type = response.data.type;
        }
      });
    },
    createContract() {
      this.contract.loading = true
      this.$v.$touch();

      if (this.contract.value) {
        api
          .post('investments/contracts', {
            type: this.type.id,
            value: this.contract.value,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.value = '0.00'
              this.$v.$reset()

              this.$router.push('/investments/contracts/' + response.data.contract.id);
            } else {
              this.password = ''
              this.$v.$reset()

              this.alert.contract.type = 'alert-danger'
              this.alert.contract.message = response.data.message
            }

            this.contract.loading = false
          })
      }
    }
  },
  mounted() {
    this.getType();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ type.name }}</h4>
        </div>
      </div>
    </div>
    <template v-if="type.id">
      <div class="alert alert-warning border-0 px-4 py-3">
        Insira o valor que deseja investir nesta operação!<br>
        O valor mínimo do investimento é de <strong>{{ type.value_min | currency }}</strong>.
      </div>
      <div class="card">
        <div class="card-body p-4">
          <b-form class="px-2" @submit.prevent="createContract()">
            <div v-if="alert.contract.message" :class="'alert ' + alert.contract.type">{{ t(alert.contract.message) }}</div>
            <b-form-group id="value" label="Valor do Investimento" label-for="value">
              <b-form-input id="value" v-model="contract.value" v-money="money" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.contract.value.$error }"></b-form-input>
              <div v-if="$v.contract.value.$error" class="invalid-feedback">
                <span v-if="!$v.contract.value.required">{{ t('Amount is required.') }}</span>
              </div>
            </b-form-group>
            <div class="mt-4">
              <b-button :disabled="contract.loading == true || this.contract.value==='0,00'" type="submit" variant="default">
                Investir
                <b-spinner v-if="contract.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </template>
    <div v-else class="text-center">
      <b-spinner
        small
        class="ml-2 align-middle"
        variant="default"
        role="status"
      ></b-spinner>
    </div>
  </Layout>
</template>